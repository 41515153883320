import './assets/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// import PrimeVue from 'primevue/config';
// import Ripple from 'primevue/ripple';
// import Galleria from 'primevue/galleria';
// import Button from 'primevue/button';

const app = createApp(App);
// app.use(PrimeVue, { ripple: true });
app.use(router);

// app.directive('ripple', Ripple);


// app.component('Galleria', Galleria);
// app.component('Button', Button);


app.mount('#app');