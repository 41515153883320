<template>
    <div class="content">
        <h1>RPC คืออะไร? (Remote Procedure Calls)</h1>
        <div>
            Remote Procedure Call คือความสามารถของ client application ในการไป execute subroutine ที่ remote server
        </div>
        <img src="../../../../assets/img/what-is-rpc-1.png" alt="what-is-rpc-1.png" class="img">
        <div>feature เฉพาะของ RPC คือ การใช้งาน remote method invocation นั้นจะเหมือนกับการใช้งาน local method ในแง่ของ developer code เลย ซึ่งบางครั้ง feature นี้ของ RPC มักเรียกว่า location transparency (หรือก็คือการ executed locally หรือ remotely มันจะคล้ายกัน)</div>
        <div>
            อีก unique feature ของ RPC คือ RPC frameworks นั้น support multiple programming languages นั้นหมายความว่า application ที่เขียนโดยใช้ programming languages ที่แตกต่างกันก็สามารถสื่อสารกับโดยใช้ RPC ได้
        </div>
        <h1>RPC ทำงานอย่างไร?</h1>
        <div>data types ที่จะใช้ใน API methods ของ PRC จะถูกประกาศโดย interface description language (IDL) ซึ่งก็จะแตกต่างกันออกไปในแต่ละ RPC framework implementations ซึ่งจริงๆแล้วมันคือ schema definition ในการสื่อสารระหว่าง remote client และ server ซึ่ง IDL ถือว่าเป็นส่วนหนึ่งของ system เราอยู่นะ โดย IDL จะใช้ language พิเศษ </div>
        <div>
            ซึ่งเมื่อคุณมี IDL แล้ว คุณสามารถใช้ special compiler หรือ code generation tool ต่างๆ ที่เป็นส่วนหนึ่งของ RPC framework เพื่อ generate 2 implementations แยกกันคือฝั่ง server application อันนึงและฝั่ง client application อีกอันนึง
        </div>
        <div>
            โดย server side implementation (หรือก็คือ RPC method ของฝั่ง server) ที่ generate มาจะเรียกว่า Server Stub ส่วนฝั่ง client ก็จะเรียกว่า Client Stub โดย stubs เหล่านี้จะดำเนินงานภายในของ remote method invocation ให้เรา
        </div>
        <img src="../../../../assets/img/how-rpc-work-1.png" alt="how-rpc-work-1" class="img">
        <div>
            โดยทุก custom object types ที่เรากำหนดโดยใช้ IDL จะถูก compiled เป็น classes หรือ structs ซึ่งก็จะขึ้นกับ programming language ของ application นั้น ซึ่งมันจะ auto generated object types ที่มักจะเรียกว่า Data Transfer Objects หรือ DTOs
        </div>
        <img src="../../../../assets/img/how-rpc-work-2.png" alt="how-rpc-work-2" class="img">
        <div>จากนั้น ในช่วง running time เมื่อ client application เรียก RPC method โดยใส่ parameters เข้าไป ก็จะทำให้ client stub ทำการ serialization/marshalling หลังจาก serialization ก็จะ initiate connection ไปที่ remote server application แล้วก็ส่ง data ผ่านเข้า remote server stub จากนั้น server stub ของ RPC method นั้นก็จะเอา message นั้นมา deserialized แล้วก็ process message นั้นที่ server ต่อ (ในรูปก็คือทำ Debit Account Implementation เป็นต้น)</div>
        <img src="../../../../assets/img/how-rpc-work-3.png" alt="how-rpc-work-3" class="img">
        <div>
            เมื่อ server ทำการ process เสร็จก็จะ response กลับไปโดยผ่าน server stub ก่อน เพื่อทำการ serialization/marshalling data ที่จะ response จากนั้นก็ส่งผ่าน network ไป เมื่อถึง client ก็จะผ่านเข้า client stub ของ RPC method นั้นๆ จากนั้นก็จะทำการ deserialization/Unmarshalling แล้วก็ส่งกลับไปให้ caller ของ client เป็น return value นั้นเอง
        </div>
        <img src="../../../../assets/img/how-rpc-work-4.png" alt="how-rpc-work-4" class="img">
        <h1>ข้อดีของการใช้ RPC</h1>
        <div>มันสะดวกสำหรับ client application developers เนื่องจากเมื่อพวกเราจะทำการสื่อสารกับ API ของเรา เขาแค่ทำการเรียก methods บน object เหมือนกับการเรียก local method เลย โดย detail ของ communication หรือ data transfer ระหว่าง client กับ server นั้น developers ไม่จำเป็นต้องรู้เลยด้วยซ้ำ </div>
        <div>ซึ่งเมื่อเกิด failures ใน communication มันจะได้ result มาเป็น error หรือ exception ของ programming language นั้นเลย</div>
        <h1>ข้อเสียของการใช้ RPC</h1>
        <div>มันไม่ได้เหมือน local method จริงๆที่จะ executed บน client-side เนื่องจาก remote methods ของ RPC นั้นจะแตกต่างกับ local method เช่น</div>
        <div>1. Slower: โดย client จะไม่มีทางรู้ว่าจะใช้เวลานานเท่าไหร่กว่าจะได้ result จาก remote method invocations ซึ่งความช้านี้สามารถจัดการได้โดยใช้ asynchronous version (ใช้ async version กับ method ที่ทำงานช้า) เป็นต้น</div>
        <div>2. Less Reliable: เนื่องจาก client อาจไม่ได้อยู่ company เดียวกับ API server และมันสื่อสารกันผ่าน network</div>
    </div>
</template>

<script>


export default {
    
}




</script>
<style lang="scss" scoped>
.content {
    padding: 0rem 5rem;
    animation: moveInBottom .5s ease-out .1s;
    animation-fill-mode: backwards;
    .img {
        margin-top: 1rem;
        width: 50%;
    }
}
</style>