<template>
    <div class="content">
        <h2>Systems นั้นมักถูก redesigned ไม่ใช่เพราะเรื่องของ functional requirements แต่เป็นเพราะ system นั้นมีปัญหาดังนี้</h2>
        <ul>
            <li>Isn’t fast enough</li>
            <li>Doesn’t scale</li>
            <li>Slow to develop</li>
            <li>Hard to maintain</li>
            <li>Not secure enough</li>
        </ul>
        <h1>ความสำคัญของ Quality Attributes</h1>
        <h3>1. Testability and Measurability</h3>
        <div>Quality Attributes จะต้อง Measurable และ Testable ได้ โดยถ้าเราไม่สามารถ prove system เรื่องของ quality attributes ของเราโดยการ Measurable และ Testable ได้ เราจะไม่มีทางรู้ได้เลยว่า system นั้นทำงานได้ดีตามที่ requirement กำหนดหรือป่าว นั้นเอง</div>
        <div>ตัวอย่าง Unmeasurable Quality Attributes → “When user clicks on the buy button, the purchase confirmation must be displayed quickly the the user” → เราจะไม่รู้ว่า quickly ในที่นี้หมายถึงยังไง แม้เราจะ design system ให้มันแสดง confirmation popup ประมาณ 200 ms เราก็จะไม่มีทางรู้ว่าเวลาประมาณนี้มันดีพอแล้วหรือยัง</div>
        <h3>2. Tradeoffs</h3>
        <ul>
            <li>ไม่มี single software architecture ที่ให้ทุก quality attributes</li>
            <li>บาง Quality Attributes อาจขัดแย้งกันได้ หรือก็คือบางการรวมกันของ Quality Attributes อาจทำได้ยากหรือเป็นไปไม่ได้ เป็นต้น</li>
            <li>หน้าที่ของ Software Architects คือต้องทำ tradeoff(ข้อแลกเปลี่ยน) ที่ถูกต้องและดีที่สุด</li>
            <li>ตัวอย่างการ Trade off นั้น ที่เห็นได้ชัดคือการ trade off ระหว่าง Performance และ Security ที่การเพิ่ม security หลายๆอย่างมักทำให้ระบบช้าลงเป็นต้น</li>
        </ul>
        <h3>3. Feasibility(ความเป็นไปได้) ของ requirement</h3>
        <ul>
            <li>เราต้องทำให้แน่ใจว่า system มีความเป็นไปได้ตามที่ client ต้องการได้จริงๆ เนื่องจากบางครั้ง client อาจต้องการสิ่งที่มัน Technically impossible, Prohibitively expensive to implement</li>
            <li>ตัวอย่างเช่น
                การมี data center อยู่ที่อเมริกา กับ client ที่อยู่ที่ อเมริกาใต้ โดยมันจะมีเวลาในการสื่อสารอยู่ที่ 100 - 150 ms นั้นคือเราไม่มีทางทำให้การส่ง page มันน้อยกว่า 100 ms แน่ๆ และอีกอย่างก็คือเนื่องจากเราใช้ HTTP นั้นหมายความว่ามันต้องรับส่ง request หลายรอบด้วยนั้นเอง
                <img src="../../../../assets/img/qa-1.png" alt="qa-1" class="img">
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.content {
    padding: 0rem 5rem;
    animation: moveInBottom .5s ease-out .1s;
    animation-fill-mode: backwards;
    .img {
        margin-top: 1rem;
        width: 50%;
    }
}
</style>