<template>
    <div>
        <section class="blog__header">
            <router-link :to="{ name: 'Home'}" style="text-decoration: none; color: inherit;">
                <div class="blog__header--nav">
                    <!-- <img src="../../../assets/img/left-arrow.png" alt="left-arrow" class="arrow-image"> -->
                    <div class="arrow-image"></div>
                    <div class="nav-text">
                        HOME
                    </div>
                </div>
            </router-link>
            <div class="blog__header--title">
                {{ blogSnapshot.title }}
            </div>
            <!-- <div class="blog__header--detail">
                <div>
                    27 May 2020 * 
                </div>
                <div>
                    2 minuted read
                </div>
            </div> -->
        </section>
        <router-view/>
    </div>
</template>
<script>
import getBlog from '../../../composables/getBlog'
import { useRoute } from 'vue-router'

export default {
    name: "Blog",
    setup() {
        const route = useRoute()
        const { blogSnapshot } = getBlog(route.params.id);
        return { blogSnapshot }
    }
}
</script>
<style lang="scss">
@import "../../../assets/main.scss";
.blog {
    &__header {
        padding: 0px 15rem;

        @include respond(tab-1650px) {
            padding: 0px 7rem;
        }
        &--nav {
            display: flex;
            align-items: center;
            padding: 0.3rem;
            width: 5rem;
            transition: all .2s;
            border-radius: 0.5rem;
            
            &:hover {
                background-color: var(--color-yellow);
                box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
                color: black;
                transform: translateY(-2px);
            }
            
            .arrow-image {
                width: 16px;
                height: 16px;
                margin-right: 3px;
                background-image: url('../../../assets/img/left-arrow.png');
                background-size: cover; 
            }
            &:hover .arrow-image {
                background-image: url('../../../assets/img/left-arrow-black.png');
            }   
            .nav-text {
                font-size: 18px;
            }
        }
        &--title {
            font-size: 72px;
        }
        &--detail {
            display: flex;
            align-items: center;
        }
    }
}
</style>