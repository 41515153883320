<template>
  <div>
    <section class="header ">
      <!-- grid -->
      <div class="header__description ">
        <!-- col-8 -->
        <div class="header__description--main">
          <span class="heading-tertiary">HEY, I'M</span>
          <span class="text__highlight"> <span class="heading-primary">Chanon Manojit.</span> </span>
          <span class="heading-secondary">My nick name is 
            <span class="text__highlight"> 
              <span class="heading-primary">Mick. 🔥</span>
            </span>   
          </span>
        </div>
        
        <div class="header__description--sub">
          <span class="heading-secondary">I’m a full-stack developer from Thailand and an expert in application development.</span>
        </div>
      </div>
      <div class="header__image ">
        <!-- col-4 -->
        <img src="../../../assets/img/profile.jpg" class="profile__image" alt="avatar">
        <figcaption class="profile__caption">Chanon Manojit</figcaption>
      </div>
    </section>

    <section class="content">
      <div class="content__navigation">
          <!-- <div 
            :class="{ 'content__navigation--text-highlight--work-project': page === 'p', 
            'content__navigation--text-highlight--my-blog': page === 'b', 
            'content__navigation--text-highlight--about-me': page === 'm' 
          }"></div> -->
          <span class="content__navigation--item" :class="{'content__navigation--text-highlight--my-blog': page === 'b' }">
            <span class="content__navigation--text" @click="handleClick('b')" :style="{ color: colorTextMyBlog }">My Blog</span>
          </span>
          <!-- <span class="content__navigation--item" :class="{'content__navigation--text-highlight--work-project': page === 'p' }">
            <div class="content__navigation--text" @click="handleClick('p')" :class="{ 'active': page === 'p' }">My Work Project</div>
          </span> -->
          <span class="content__navigation--item" :class="{'content__navigation--text-highlight--about-me': page === 'm' }">
            <span class="content__navigation--text" @click="handleClick('m')" :style="{ color: colorTextAboutMe }">About Me</span>
          </span>
        
      </div>
      <div class="content__project" v-if="page == 'p'">
        <Project />
      </div>
      <div class="content__blog" v-if="page == 'b'">
        <Blog />
      </div>
      <div class="content__aboutme" v-if="page == 'm'">
        <AboutMe />
      </div>
      
    </section>

    <footer class="footer">
        <div class="footer__row">
            <div class="footer__col">
                <div class="footer__navigation">
                    <div class="footer__item"><a href="https://www.facebook.com/chanon.manojit" class="footer__link">facebook</a></div>
                    <div class="footer__item"><a href="https://github.com/Chanon2000" class="footer__link">Github</a></div>
                    <!-- <ul class="footer__list">
                        <li class="footer__item"><a href="https://www.facebook.com/chanon.manojit" class="footer__link">facebook</a></li>
                        <li class="footer__item"><a href="https://github.com/Chanon2000" class="footer__link">Github</a></li>
                    </ul> -->
                </div>
            </div>
            <div class="footer__col">
                <div class="footer__copyright">
                    © 2021-2022 Chanon Manojit
                </div>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue'
import Project from './components/Project.vue'
import Blog from './components/Blog.vue'
import AboutMe from './components/AboutMe.vue'
import { computed } from 'vue'


export default {
  name: 'Home',
  components: { Project, Blog, AboutMe },
  setup() {
    const page = ref('b')

    const handleClick = (ch) => {
      page.value = ch
    }
    

    const colorTextworkProject = computed(() => { 
      if (page.value == 'p') {
        return "#000000"
      } else {
        return "#fff"
      }
    })
    const colorTextMyBlog = computed(() => { 
      if (page.value == 'b') {
        return "#000000"
      } else {
        return "#fff"
      }
    })
    const colorTextAboutMe = computed(() => { 
      if (page.value == 'm') {
        return "#000000"
      } else {
        return "#fff"
      }
    })

    return { page, handleClick, colorTextworkProject, colorTextMyBlog, colorTextAboutMe }
  },
  mounted() {

  }
}

</script>

<style lang="scss">
@import "../../../assets/sass/mixins";

.text__highlight {
  background: var(--color-yellow);
  border-radius: 1.1rem;
  font-size: 3rem;
  padding: 0 .94rem;
  transform: rotate(2deg);
  display: inline-block;
  margin-bottom: .31rem;

  &--nav {
    background: var(--color-yellow);
    border-radius: .25rem;
    padding: 0 .94rem;
    color: #000000;
    font-size: inherit;
    transform: rotate(-2deg);
    margin-bottom: 0px;
  }
}

.heading-primary {
  font-size: 3rem;
  font-weight: 600;
  color: #000000;
  display: inline-block;
  transform: rotate(-2deg);
}

.heading-tertiary {
  display: block;
  font-size: 1rem;
  margin-bottom: 0.31rem;
}

.heading-secondary {
  display: block;
  font-size: 1.25rem;

  &--nav {
    transform: rotate(2deg);
  }
}

.grid {
  @include respond(phone) {
    flex-direction: column;
  }
}

</style>