<template>
    <div class="content">
        <div>List ของ Quality Attributes นั้นมีเยอะมากเช่น Performance, Scalability, Availability, Testability, Deployability, Maintainability, Portability, Security, Observability, Consistency, Efficiency, Usability ซึ่งมีความสำคัญกับ system ทุกอัน แต่บทความนี้เราจะพูดถึง quality attributes แรกเลยก็คือ Performance </div>
        <h1>Performance คืออะไร?</h1>
        <div>เมื่อเราพูดถึง performance เรามักจะนิดถึง speed นั้นคือเมื่อเรา interact กับ system ที่ respond ให้กับ action ของเราได้เร็ว เราก็จะคิดว่า system นั้นมี high performance</div>
        <ul>
            <li>
                <div>Response Time คือหนึ่งใน performance metrics ซึ่งมีความหมายคือ “Time between a client sending a request and receiving a response”</div>
                <div>โดยสามารถคำนวณได้คือ Response Time = Processing Time + Waiting Time</div>
                <div>ซึ่ง Processing Time → Time spent in our system actively process in the request and building/sending the response</div>
                <div>Waiting Time → เป็นเวลาที่มักถูกลืมเอามาคำนวณใน Response Time โดยมันคือ Duration of time request/response spends inactively in our system (เช่นรออยู่ใน software queues หรืออะไรก็ตามที่เป็นการรอเพื่อทำให้ประมวณผล) </div>
                <div>โดย response time เป็น performance metrics ที่มีความสำคัญมาก เมื่อ designing system หรือ subsystem อะไรก็ตาม เนื่องจาก users จะมีความอดทนน้อยสำหรับ response times ที่ใช้เวลานาน หรือก็คือจะเสียความอดทนเมื่อ system ไม่ตอบสนองต่อ action ของ user นั้นๆโดยทันทีทันใด</div>
                <img src="../../../../assets/img/performance-1.png" alt="performance-1" class="img">
            </li>
            <li>
                <div>Throughput คือหนึ่งใน performance metrics ที่มีความสำคัญ โดย Throughput คือ “Amount of work performed by our system per unit of time” (tasks/second) หรือบางทีก็แล้วแต่ use case นั้นคืออาจมีความหมายว่า “Amount of data processed by our system per unit of time” (เช่น bits/second, Bytes/second, MBytes/second)</div>
                <div>โดยบาง service นั้น Response Time อาจเป็น performance metrics ที่มีความสำคัญที่สุดแต่บาง service นั้นไม่ เช่น</div>
                <ul>
                    <li>
                        <div>เมื่อคุณ designed เป็น distributed logging system ที่จะทำการ aggregates และ analyzes ข้อมูล logs จาก servers ต่างๆนับร้อย ซึ่งคุณจะคิดว่าว่า system นี้นั้น performance metric ที่สำคัญที่สุดจะไม่ใช่ response time แต่เป็น ความสามารถในการ ingest และ analyze data ในเวลาที่กำหนด นั้นคือยิ่ง system นี้ ingest และ analyze data per unit of time ได้มากขึ้นก็แสดงว่า system มี higher performance นั้นเอง ซึ่ง performance metric ที่เราจะใช้ให้กับ system นี้ก็คือ Throughput นั้นเอง</div>
                        <img src="../../../../assets/img/performance-2.png" alt="performance-2" class="img">
                    </li>
                </ul>
            </li>
        </ul>
        <h1>Considerations ที่ควรรู้</h1>
        <h2>1. ควรจะคำนวณ response time ให้ถูกต้อง → เนื่องจาก engineer มักวัด response time แค่ Processing Time โดยลืมเอา Waiting Time มาคำนวณ</h2>
        <div>(รูปล่างนิคือสมมุติว่ามัน process ที่ละ request นะ)</div>
        <div class="img-hor">
            <img src="../../../../assets/img/performance-3.png" alt="performance-3" class="img">
            <img src="../../../../assets/img/performance-4.png" alt="performance-4" class="img">
        </div>
        <div>ได้ Average Response Time คือ 15 เนื่องจาก response time 2 นั้นต้องรอ request 1 ทำงานจนเสร็จตามรูปที่เห็นข้างบน เลยมี Waiting Time ตามเวลาของ response time 1 </div>
        <h2>2. เราจะคำนวน response time จาก distribution system โดยใช้วิธี percentile distribution chart </h2>
        <div class="img-hor">
            <img src="../../../../assets/img/performance-5.png" alt="performance-5" class="img">
            <img src="../../../../assets/img/performance-6.png" alt="performance-6" class="img">
        </div>
        <div>(บาง response time ก็ดีมาก บางอันก็แย่มาก)</div>
        <div>เนื่องจาก แต่ละ servers ก็จะมี response time ที่แตกต่างกัน นั้นทำให้เราเกิดคำถามว่าเราจะคำนวณ response time ยังไง? (Average, Median, Maximun)นั้นคือทำการ analyzing distribution นี้โดยการ sort response time ของ samples แล้ว bucket พวกมันลง histogram โดย histogram มันจะแสดงความถี่ของแต่ละ response time ซึ่งทำให้เราได้รูปที่ดีกว่า เพื่อเอาไปวิเคราะห์ response times ของ distribution system นี้</div>
        <img src="../../../../assets/img/performance-7.png" alt="performance-7" class="img">
        <div>โดยจาก histogram กราฟนี้ให้เราสามารถสร้าง percentile distribution chart หรือ table ได้ง่ายขึ้น เช่น</div>
        <img src="../../../../assets/img/performance-8.png" alt="performance-8" class="img">
        <ul>
            <li>ถ้า 90% ของ request นั้นจะมี response time คือ 52 ms</li>
            <li>section ที่วงสีแดงของกราฟนั้นจะเรียกว่า Tail Latency โดยมีความหมายคือ “The small percentage of response times from a system, that take the longest in comparison to the rest of values” ซึ่ง Tail Latency ยิ่งสั้นนั้นคือมี performance ของ system ที่ดี</li>
            <li>
                <div>ตัวอย่างในการกำหนด Response Time Goals</div>
                <ul>
                    <li>“30ms at 95th percentile of response time” → หมายความว่าเราอนุญาติให้แค่ 5% ของ requests มี response time คือ 30ms เท่านั้น</li>
                    <li>“30ms at 99th percentile of response time”</li>
                </ul>
            </li>
        </ul>
        <h2>3. การหา Performance degradation point → คือการหาจุดที่ performance ของ system นั้นเริ่มแย่ลง</h2>
        <img src="../../../../assets/img/performance-9.png" alt="performance-9" class="img">
        <div>ถ้าคุณเห็นว่า system’s performance นั้น degrades อย่างรวดเร็วดังรูปล่าง มันมักจะหมายความว่าหนึ่งใน resource ของเรานั้นใช้งานอย่างเต็มที่แล้ว</div>
        <img src="../../../../assets/img/performance-10.png" alt="performance-10" class="img">
        <div>ซึ่ง resource ที่ว่านั้นอาจเป็น </div>
        <ul>
            <li>High CPU utilization</li>
            <li>High memory consumption</li>
            <li>Too many connections/IO</li>
            <li>Message queue is at capacity</li>
        </ul>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.content {
    padding: 0rem 5rem;
    animation: moveInBottom .5s ease-out .1s;
    animation-fill-mode: backwards;
    .img {
        margin-top: 1rem;
        width: 50%;
    }
    .img-hor {
        display: flex;
        flex-direction: column;
    }
}
</style>