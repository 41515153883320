<template >
    <div class="content">
        <h1>System Constraints คืออะไร?</h1>
        <div>“A system constraint is essentially a decision that was already either fully or partially made for us, restricting out degrees of freedom.”</div>
        <div>System Constraints ไม่ใช่เป็นเรื่องที่ไม่ดีนะ เพราะว่า บางครั้งเมื่อเรามองหา Constraints มันอาจตัดตัวเลือกของเราไป แต่เราก็สามารถมองหามันเหมือนเป็น decision ที่เตรียมไว้ให้เราอยู่แล้วได้ ซึ่งนั้นมันทำให้งานเราง่ายขึ้นด้วยนะ</div>
        <div>นั้นทำให้บางครั้งคนเรียก System Constraints ว่าเป็น เสาหลักของ software architecture นั้นก็เพราะว่า Constraints มันให้ solid starting point(จุดเริ่มต้นที่มั่นคง) ที่ดี และ เรามัก design system ส่วนที่เหลือ ต่อจาก Constraints ที่มีเหล่านั้น</div>
        <h1>มี Constraints อยู่ 3 ประเภท</h1>
        <h2>1. Technical constraints</h2>
        <div>คือสิ่งที่ developer อาจเจออยู่ทุกวัน เช่นด้านล่างนี้ </div>
        <ul>
            <li>ถูกล็อคโดย particular hardware/cloud vendor (ทำให้ย้ายไป cloud อื่นไม่ได้เป็นต้น)</li>
            <li>Having to use a particular programming language → ทำให้เราใช้บ้าง tech ไม่ได้ หรือจ้าง developer มาเขียนได้ยากขึ้นเป็นต้น</li>
            <li>Having to use a particular database or technology, or OS  </li>
        </ul>
        <div>ซึ่งทำให้ดูเหมือนว่ามันเป็น Technical constraints เป็น constraints ของ implementation ไม่ใช่ของ software architecture แต่จริงๆแล้ว Technical constraints มันส่งผลมาที่ design phrase ด้วย เช่น</div>
        <ul>
            <li>ถ้า company เราตัดสินใจจะรัน services ของตัวเองลง on-premise services นั้นอาจทำให้ cloud architectures หรือ paradigms อาจ unavailable กับเราได้ เป็นต้น</li>
            <li>ถ้าเราต้องการให้ app เรานั้น support พวก older browsers หรือ low-end mobile devices นั้น เราอาจถึงขั้นต้องปรับ architecture ให้ support platforms และ APIs ของ device เหล่านั้น</li>
        </ul>
        <h2>2. Business constraints</h2>
        <div>โดยปกติแล้ว engineers นั้นจะตัดสินใจและเลือก architecture จาก Technical perspective(มุมมอง) แต่เขามักจะไม่สามารถประเมินได้เสมอไปว่าตัวเลือกเหล่านั้นเหมาะกับ business หรือป่าว ซึ่งด้วยเหตุผลนั้นเราก็เลยมักจะได้ constraints จาก business team ซึ่ง constraints เหล่านั้นจะทำให้เราต้องเสียสละตัวเลือกในทั้งแง่ของ architecture และ implementation เช่น limited budget, strict deadline ทำให้ตัด chioces ที่มีค่าใช้จ่ายมากๆหรือใช้เวลาทำนาน</div>
        <div>software architectural patterns ที่แตกต่างกัน บางอันก็จะเหมาะกับ small startups บางอันก็จะเหมาะกับ bigger organizations เช่น บาง architecture ก็ทำให้เราเอา products เข้าตลาดได้เร็วและใช้ team เล็กๆทำได้</div>
        <div>บาง Business constraints บอกให้เราใช้ third-party services เป็นส่วนนึงของ architecture เราด้วย เช่น</div>
        <ul>
            <li>ใช้พวก third-party shipping/billing providers เพื่อเอาไว้จ่ายเงิน</li>
            <li>ถ้าทำพวก investment platform คุณก็อาจจะต้อง integrate system ของเราเข้ากลับหลาย third-party เช่น banks, broker services, security, fraud detection services เป็นต้น</li>
        </ul>
        <h2>3. Regulatory/legal constraints (ข้อบังคับ/กฎหมาย)</h2>
        <div>Regulatory constraints อาจเป็นเช่น Global, specific to a region (คือข้อบังคับเฉพาะในพื้นที่นั้นๆ) เช่น</div>
        <ul>
            <li>ใน US ก็จะมี HIPAA (Health Insurance Portability and Accountability Act) ซึ่งถ้าคุณทำระบบที่จัดการเรื่อง medical information หรือ patient’s record คุณควรจะปฏิบัติตาม HIPAA regulations ที่ซึ่งจะมาวาง constraints เช่นว่าใครสามารถเข้าถึง patient’s data ได้บ้าง หรือว่าคุณต้องใส่ security ตรงใหนบ้างเพื่อป้องกัน sensitive information</li>
            <li>ใน European Union ก็จะมี GDPR ที่กำหนด limitations บน online services ซึ่งเข้ามาจำกัดเช่น data ของ user อะไรบ้างที่ system เหล่านั้นสามารถเก็บได้หรือ share ได้</li>
        </ul>
        <h1>มี 2 Considerations ที่คุณควรรู้</h1>
        <h2>1. we shouldn't take any given constraint lightly</h2>
        <div>หมายความว่าคุณต้องแยก constraint ให้ได้ว่ามันเป็น real constraint หรือ self-imposed constraint</div>
        <div>เช่น ถ้าคุณทำงานกับ external rules และ regulations นี้อาจทำให้เราไม่สามารถเปลี่ยนแปลง constraint ได้ แต่ถ้าเราได้พวก budget และ time constraint (เรียกว่า internal constraint) เราอาจสามารถขอเพิ่ม budget หรือยืดเวลาทำงานได้ นั้นเอง </div>
        <h2>2. Use loosely coupled architecture</h2>
        <div>ใช้ architecture ให้หลวมๆ เพื่อที่จะได้หลุดพ้น constraints ที่เกิดขึ้นจาก architecture ที่กำลังใช้อยู่ ในอนาคตได้</div>
        <div>เช่น เมื่อคุณใช้ database หรือ third-party service ใหนอยู่ก็ตาม คุณต้องตรวจสอบด้วยว่า system ของคุณจะไม่ยึดติดกับ technology หรือ APIs นั้นอย่างหนาแน่นเกินไป เพื่อที่ในอนาคตเราจะสามารถเปลี่ยนแปลง technology เหล่านั้นไปใช้อย่างอื่นได้ง่ายๆ แทนการต้อง re-architecting ทั้ง system เป็นต้นนั้นเอง</div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.content {
    padding: 0rem 5rem;
    animation: moveInBottom .5s ease-out .1s;
    animation-fill-mode: backwards;
    .img {
        margin-top: 1rem;
        width: 50%;
    }
}
</style>