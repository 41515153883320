<template>
    <div class="project_card" v-for="content in contents" :key="content.id">
        <div class="project_card__image">
            <img src="../../../../assets/img/nat-6.jpg" alt="project image" >
        </div>
        
        <div class="project_card__text">
            <div class="project_card__text--title">
                <span>{{ content.title }}</span>
            </div>
            <div class="project_card__text--description">
                <span>
                    {{ content.description }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
// import getContents from '../../composables/getContent'

export default {
    name: 'Project',
    setup() {
        // const { contents, error, load } = getContents("project")
        // load()
        const contents = ref([
        {
            itemImageSrc: "../../../../assets/img/profile.jpg",
            thumbnailImageSrc: "assets/img/profile.jpg",
            alt: "Description for Image 1",
            title: "CMUe-Donation",
            description: "Description for Image",
            link: "",
        },
        {
            itemImageSrc: "../../../../assets/img/nat-6.jpg",
            thumbnailImageSrc: "assets/img/nat-6.jpg",
            alt: "Description for Image 2",
            title: "Title 2",
            description: "Description for Image",
            link: "",
        },
        {
            itemImageSrc: "../../../../assets/img/nat-6.jpg",
            thumbnailImageSrc: "assets/img/nat-6.jpg",
            alt: "Description for Image 3",
            title: "Title 3",
            description: "Description for Image",
            link: "",
        },
        {
            itemImageSrc: "../../../../assets/img/nat-6.jpg",
            thumbnailImageSrc: "assets/img/nat-6.jpg",
            alt: "Description for Image 4",
            title: "Title 4",
            description: "Description for Image",
            link: "",
        }
        ])

        return { contents }
    },
    mounted() {

    }
}
</script>

<style lang="scss">
.project_card {
    width: 38rem;
    height: 14.6rem;
    transform: skewX(-7deg);
    background-color: #2FDD92;
    border: .6rem solid #fff;
    overflow: hidden;
    display: flex;
    transition: all .2s;
    outline-offset: .5rem;

    &:hover {
        outline: .5rem solid var(--color-yellow);
        transform: scale(1.05) translateY(-.5rem) skewX(-7deg);
        box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, .5);
        z-index: 20;
    }

    &__image {
        width: 50%;
        img {
            transform: translateX(-1.5rem) skewX(7deg);
            width: 20rem;
            height: 14rem;
            -webkit-clip-path: polygon(0 0, 100% 0, 81% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
        }
    }

    &__text {
        width: 50%;
        transform: skewX(7deg);
        margin: .5rem;
        &--title {
            color: black;
            font-size: 1.5rem;
        }
        &--description {
            
        }
    }
}
</style>