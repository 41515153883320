import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/modules/home/Home.vue'
import BlogDetail from '../views/modules/blogs/BlogDetail.vue'
import RpcBlog from '../views/modules/blogs/components/RpcBlog.vue'
import QualiyAttrReq from '../views/modules/blogs/components/QualiyAttrReq.vue'
import SystemConstraints from '../views/modules/blogs/components/SystemConstraints'
import Performance from '../views/modules/blogs/components/Performance.vue'
import Scalability from '../views/modules/blogs/components/Scalability'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/blogs',
    name: 'BlogDetail',
    component: BlogDetail,
    children: [
      {
        path: 'rpc-blog/:id',
        name: 'RpcBlog',
        component: RpcBlog,
      },
      {
        path: 'system-quality-req/:id',
        name: 'QualiyAttrReq',
        component: QualiyAttrReq,
      },
      {
        path: 'system-constraints/:id',
        name: 'SystemConstraints',
        component: SystemConstraints,
      },
      {
        path: 'performance/:id',
        name: 'Performance',
        component: Performance,
      },
      {
        path: 'scalability/:id',
        name: 'Scalability',
        component: Scalability,
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
