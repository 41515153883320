<template>
    <div class="content">
        <h1>ความหมายของ Scalability</h1>
        <div>ความหมายแบบทางการจะหมายถึง “The measure of a system’s ability to handle a growing amount of work, in an easy and cost effective way, by adding resources to the system”</div>
        <div>
            ตัวอย่าง Linear scalability (ซึ่งเป็นสิ่งที่ได้ยากมากนะ ในงานจริง) นั้นคือเมื่อเราเพิ่มจำนวน resource อีก เราก็จะได้ จำนวนของงานเพิ่มแบบ Linear
        </div>
        <img src="../../../../assets/img/scalability-1.png" alt="scalability-1" class="img">
        <div>ซึ่งปกติแล้วเมื่อเราเพิ่ม resource เข้าไปอีก มันจะได้งานเพิ่มขึ้นแบบเส้นเขียวและเหลืองนะ แต่ถ้าเพิ่ม resource แล้วได้งานแบบเส้นแดง นั้นหมายความว่าคุณไม่ควรเพิ่ม resource นะ เพราะว่ามันทำให้คุณได้งานน้อยลง</div>
        <img src="../../../../assets/img/scalability-2.png" alt="scalability-2" class="img">
        <h1>ประเภทของ Scalability (เราสามารถ scale system ของเราได้ 3 orthogonal(ตั้งฉาก) dimensions)</h1>
        <div>
            จะเห็นว่าทั้ง 3 Scalability มัน orthogonal(ตั้งฉาก) นั้นคือเราสามารถ scale dimensions ใหนก็ได้หรือ scale ทั้ง 3 dimensions พร้อมกันเลยก็ได้
        </div>
        <img src="../../../../assets/img/scalability-3.png" alt="scalability-3" class="img">
        <h2>1. Vertical scalability → หมายถึง “Adding resources or upgrading the existing resources on a single computer, to allow our system to handle higher traffic or load”</h2>
        <div>สมมุติว่าเรามี service ที่รันบน single computer ที่มี traffic เข้ามาจากหลาย users ซึ่งเมื่อจำนวน users มาขึ้นเรื่องๆจนถึงจุดที่ system ไม่สามารถรับ load ได้อีกซึ่งนั้นคือมันถึงจุด degradation ซึ่งหนึ่งในวิธีแก้ไขปัญหานี้ก็คือ upgrading service ของเราไปที่ machine ที่มี CPM, memory, network card (ที่จัดการ bandwidth ได้เยอะกว่า) ที่ดีกว่า ซึ่งการ upgrade นี้แหละที่เรียกว่า scale up หรือก็คือ vertical scaling นั้นเอง</div>
        <div class="img-hor">
            <img src="../../../../assets/img/scalability-4.png" alt="scalability-4" class="img">
            <img src="../../../../assets/img/scalability-5.png" alt="scalability-5" class="img">
        </div>
        <h2>2. Horizontal Scalability → หมายถึง “Adding more resources in a form of new instances running on different machines, to allow our system to handle higher traffic or load”</h2>
        <div>แทนการ upgrading hardware ที่มีอยู่แล้ว เราสามารถเพิ่ม unit ของ resources เช่นการ running service บน multiple computers แทนการรันแค่ single computer </div>
        <div>การ scale database ด้วย Horizontal Scalability ก็เช่นกัน ก็คือเพิ่มรัน database บนหลายๆ machines แทนการรันแค่เครื่องเดียว</div>
        <img src="../../../../assets/img/scalability-6.png" alt="scalability-6" class="img">
        <h2>3. Team/Organizational Scalability → คือการเพิ่มจำนวนคนใน term</h2>
        <div>จากความหมายของ Scalability คือ “The measure of a system’s ability to handle a growing amount of work, in an easy and cost effective way, by adding resources to the system” → โดยคำว่า work ในความหมายของ developer มันคือ features, testing, fixing bugs, releases , คำว่า resources ในที่นี้ก็จะหมายถึงตัว engineers</div>
        <div>การเพิ่ม engineers อาจทำให้ Productivity Degradation ได้เช่น</div>
        <ul>
            <li>Many crowded meetings</li>
            <li>Code merge conflict</li>
            <li>เมื่อ Business มัน Complexity นั้นทำให้ new developer ต้องใช้เวลาเรียนรู้นาน กว่าเขาจะ productive ได้</li>
            <li>Testing is harder and slower → เพราะการ change ของใครบางคนอาจ break บางอย่างได้เป็นต้น นั้นทำให้เมื่อเราทำ change ขึ้นมาเราต้อง testing ทุกอย่างเลย</li>
            <li>Releases become very risky → เนื่องจากการ releases ที มันจะมี changes มากมายจากหลาย developer</li>
        </ul>
        <div>ซึ่ง Software Architecture นั้นไม่ได้ impact แค่ performance แต่ impact ความเร็วในการทำงานของ engineer ด้วย (หรือก็คือ team productivity) หรือก็คือ impact Team/Organizational Scalability ด้วยนั้นแหละ</div>

    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.content {
    padding: 0rem 5rem;
    animation: moveInBottom .5s ease-out .1s;
    animation-fill-mode: backwards;
    .img {
        margin-top: 1rem;
        width: 50%;
    }
    .img-hor {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}
</style>