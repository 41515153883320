<template>
    <div class="blog_card" v-for="blog in blogs" :key="blog.id">
        <router-link :to="{ name: blog.link, params: { id: blog.id } }" style="text-decoration: none; color: inherit;">
            <div class="blog_card__text">
                <div class="blog_card__text--title">
                    <span>{{ blog.title }}</span>
                </div>
                <div class="blog_card__text--description">
                    <span>
                        {{ blog.description }}
                    </span>
                </div>
            </div>
        </router-link>
        
    </div>
</template>

<script>
// import { ref } from 'vue'
import blogs from '../../../../composables/data/blogs.json'

export default {
    name: "Blog",
    setup() {


        return { blogs }
    }
}
</script>

<style lang="scss">
.blog_card {
    width: 25rem;
    height: 14.6rem;
    transform: skewX(-7deg);
    background-color: #2FDD92;
    border: .6rem solid #fff;
    overflow: hidden;
    display: flex;
    transition: all .2s;
    outline-offset: .5rem;
    

    &:hover {
        outline: .5rem solid var(--color-yellow);
        transform: scale(1.05) translateY(-.5rem) skewX(-7deg);
        box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, .5);
        z-index: 20;
    }

    &__text {
        transform: skewX(7deg);
        margin: 1rem 1rem 1rem 1.8rem;

        &--title {
            color: black;
            font-size: 1.5rem;
        }
        // &--description {

        // }
    }
}




</style>