<template>
  <section class="navigation">
    <router-link :to="{ name: 'Home' }">
      <div class="navigation__logo">
        <span class="heading-logo">ch</span>
      </div>
    </router-link>
    

    <!-- #1. checkbox -->
    <!-- <input type="checkbox" @click="handleNavigation"  class="navigation__checkbox" id="navi-toggle" > -->

    <!-- #2. button -->
    <!-- <label for="navi-toggle" class="navigation__button">
      <span class="navigation__icon">&nbsp;</span>
    </label> -->

    <!-- #3. background -->
    <!-- <div class="navigation__background">&nbsp;</div> -->

    <!-- #4. nav -->
    <!-- <nav class="navigation__nav" v-if="checkedNav" >
      <ul class="navigation__list">
        <li class="navigation__item">
          <a href="#" class="navigation__link"><span>01</span>Login For Chanon</a>
        </li>
        <li class="navigation__item">
          <a href="#" class="navigation__link"><span>02</span>Upload Example Project</a>
        </li>
        <li class="navigation__item">
          <a href="#" class="navigation__link"><span>03</span>Upload Blog</a>
        </li>
      </ul>
    </nav> -->
  </section>
  <div class="layout">
    <router-view/>
  </div>

</template>
<script>
// import { ref } from 'vue'

export default {
  // setup() {
  //   
  // },
}
</script>
<style lang="scss">
@import "./assets/sass/mixins";
// @import "./assets/sass/navigation";

.layout {
    // margin: 10rem 10rem 3rem 10rem;
    margin-top: 10rem;
    margin-bottom: 3rem;
    // @include respond(tab-land) { // width < 1200?
    //     margin: 10rem 10rem 3rem 10rem;
    // }
    @include respond(tab-port) { // width < 900? 
      // margin: 10rem 6rem 3rem 6rem;
    }
    @include respond(phone-land) { // width < 600?
        // margin: 10rem 2rem 3rem 2rem;
    }
    // @include respond(phone-port) { // width < 400?
    //     grid-template-columns: 100%;
    // }
}

.navigation {

  &__logo {
    position: absolute;
    top: -14.25rem;
    left: -5.63rem;
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    z-index: 999;
    background-color: var(--color-green-dark);
    box-shadow: 0 1rem 3rem rgba(#000000, .1);

    .heading-logo {
      position: absolute;
      top: 4.38rem;
      left: 5.63rem;
      font-size: 4.5rem;
      font-weight: 600;
      color: #FFFFFF;
    }
  }


  &__checkbox {
    display: none;
  }


  &__button {
    position: fixed;
    top: 1.88rem;
    right: 1.88rem;
    width: 3.75rem;
    height: 3.75rem;
    background-color: var(--color-yellow);
    border-radius: 50%;
    z-index: 2000;
    box-shadow: 0 1rem 3rem rgba(#000000, .1);

    text-align: center;
    cursor: pointer;
  }

  &__background {

    // ตำแหน่งของ background
    top: 1.88rem;
    right: 1.88rem;
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    position: fixed;
    z-index: 1000;

    // สี และ animation
    background-image: radial-gradient(#FFFFFF, var(--color-yellow));
    transition: transform .8s cubic-bezier(0.83, 0, 0.17, 1);
  }

  &__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;

    opacity: 0;
    width: 0;
    transition: all .8s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  // FUNCTIONALITY
  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
      opacity: 1;
      width: 100%;
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    // normal state
    &:link,
    &:visited { 
        display: inline-block;
        font-size: 3rem;
        font-weight: 600;
        padding: 1rem 2rem;
        color: #000000;
        text-decoration: none;
        text-transform: uppercase;
        background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #FFFFFF 50%);
        background-size: 220%;
        transition: all .4s;

        span {
            margin-right: 1.5rem;
            display: inline-block;
        }

    }

    // active state
    &:hover,
    &:active { 
        background-position: 100%;
        color: var(--color-yellow);
        transform: translateX(1rem);
    }
  }


  // ICON
  &__icon {
    position: relative;
    margin-top: 1.88rem;

    &,
    &::before,
    &::after {
        width: 2rem;
        height: 2px;
        background-color: #000000;
        display: inline-block;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        transition: all .2s;
    }

    &::before { top: -.5rem; }
    &::after { top: .5rem; }
  }

  &__button:hover &__icon::before {
    top: -12px;
  }

  &__button:hover &__icon::after { 
    top: 12px;
  }

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }

}

</style>
