<template>
  <div class="aboutme">
    <h1>ข้อมูลส่วนตัว</h1>
    <p>สวัดดีครับ ผมชื่อเล่นชื่อ “มิก” ครับ ชื่อจริงชื่อ นาย ชานน มะโนจิตต์ เกิดวันที่ 2 เมษายน ปี พ.ศ 2543 เกิดที่จังหวัดเชียงใหม่ จบประถมจากโรงเรียนดาราวิทยาลัย และจบมัธยมจากโรงเรียนยุพราชวิทยาลัย
สมัยเรียนประถมกับมัธยมนั้นเป็นพวกเก่ง คณิต, เคมี, ชีวะ, ฟิสิก อย่างมาก แต่ไม่เก่งพวกวิธีภาษาต่างๆทั้งหมด และจบปริณญาตรีจาก มหาวิทยาลับเชียงใหม่ คณะวิทยาศาสตร์ สาขาวิทยาการคอมพิวเตอร์ ปัจจุบันอยู่บ้านกับครอบครัวที่เชียงใหม่ครับ เพราะรู้สึกว่ามันประหยัดค่าใช้จ่ายมากกว่าออกไปอยู่ข้างนอกคนเดียว แถมคนในครอบครัวก็ค่อนข้างให้ความเป็นส่วนตัวกับผมอย่างมาก เลยไม่ค่อยมีปัญหาในการใช้ชีวิตตอนที่อยู่บ้านเท่าไหร่ครับ</p>
    <h1>รูปห้องทำงานของผมที่บ้านครับ</h1>
    <div class="">
      <img src="../../../../assets/img/room-mick.jpeg" alt="room-mick" class="room-mick-image">
    </div>
    
    <p>ใช้ Macbook Pro M1,
จอใช้เป็น 49" มอนิเตอร์ Odyssey Neo G9 Gaming Monitor (ซื้อมาเพื่อเขียน code โดยเฉพาะเลยครับ เพราะชอบ multitasking เลยต้องการหน้าจอที่กว้างๆ),
Magic Keyboard เพราะกดง่าย, เล็กและเบาดี
Logitech MX Master เพราะเห็นคนใช้กันเลยใช้ตามแค่นั้นแหละ ซึ่งก็ถือว่าใช้งานได้ดีครับ
อื่นๆ เช่น PS5, โต๊ะ, หูฟังเป็น airpod pro ของ apple, Ergotron LX คือขาตั้ง macbook, Ergotron HX คือขาตั้งจอ 49 นิ้ว, Caldigit TS 4, เก้าอี้ คือ ErgoTrend รุ่น Wisby สีดำ</p>
    <h1>ประวัติการเรียนรู้การพัฒนา website ของผม</h1>
    <p>โดยผมเริ่มรู้จักการเขียน website จริงๆครั้งแรกตอนอายุ 20 ครับ (รู้สึกว่ารู้ช้ากว่าเพื่อนมากเลยนะ) จากนั้นก็ก็พลายามเรียนรู้การเขียน web อย่างหนักมาก (เพราะกลัวจะตามเพื่อนไม่ทัน และกลัวว่าจบไปจะหางานไม่ได้) โดยเริ่มจากการศึกษาการเขียน website จาก php โดยใช้มันเป็น api และเขียนหน้าเว็ปโดยใช้ html กับ bootstrap 5 โดย structure จะเป็น MVC เลย แล้วใช้ web server เป็น apache โดยใช้ผ่าน XAMPP อีกที
เนื่องจากได้ลงเรียนวิชา database แล้วอาจารย์สั่งให้ทำ website นี้เลยเป็นจุดเริ่มต้นของการเขียน website ตอนที่เรียนปี 3 อยู่ที่ ม.ช. ครับ
จากนั้นเมื่อผ่าน project นี้ไป เนื่องจากพอดู job offer ต่างๆ ผมได้เห็นว่า frontend เดียวนี้เขาใช้ framework เขียนในงานจริง ก็เลยเริ่มเรียนรู้ angular เป็น tool ต่อมา เลยทำให้ angular เป็น frontend framework แรกที่ผมเขียนเป็น</p>
    <h3>ช่วงฝึกงาน</h3>
    <p>จากนั้นได้มีใกล้ๆจบปี 3 ขึ้นปีที่ 4 ที่มหาลัย ผมได้มีโอกาสได้ไปฝึกงานที่ ITSC ซึ่งผมได้ร่วมงานอยู่ 2 team ในนั้นโดยไปเป็น frontend ให้กับทั้ง 2 ทีม โดยทีมนึงเขาใช้ angular เขียนหน้าเว็ปของระบบบริจากของมหาลัย ส่วนอีกทีมใช้ vueJs 2 เขียนหน้าเว็ปของระบบนึง ซึ่งผมก็ได้มีส่วนร่วมในทั้ง 2 ทีม
        จากนั้นเมื่อจบการฝึกงานผมก็ได้เห็นสิ่งที่ตัวเองไม่รู้หลายอย่าง โดยเฉพาะเครื่องมีอย่าง docker ที่เขานิยมใช้กันอย่างมาก แต่ผมดันไม่ถนัด ก็เลยทำการศึกษาอย่างหนักโดยเรียนผ่าน course ออนไลย์ ซึ่งนี้ก็ถือเป็นครั้งแรกนะที่ผมได้เข้าใจว่าทำไมถึงควรใช้เครื่องมีอย่าง docker</p>
    <h3>การทำงานที่แรก</h3>
    <p>จากนั้นช่วงใกล้ๆจบผมก็ได้รับ offer เข้าทำงานที่แรกที่บริษัทชื่อ ARISE ซึ่งเป็น software hourse แห่งหนึ่งในกรุงเทพ โดยเป็น offer WFH โดยผมก็ใช้ชีวิตอยู่ที่เชียงใหม่นี้แหละ
ซึ่งผมชอบมาก เพราะ WFH มันประหยัดค่าใช้จ่ายอย่างมาก และงานที่บริษัทจะเป็นการพัฒนาระบบของสนามกีฬาแห่งหนึ่งในกรุงเทพโดยใช้ .NET core และ angular ซึ่งถือว่าเป็น project ที่ใหญ่มากครั้งแรกของผม
โดย project นี้นั้น structure จะเป็น Monolithic Architecture โดยตัว app นั้น host อยู่ที่ Azure cloud โดยหน้าที่ของผมนั้นจะทำทั้ง frontend และ backend เลย ทำหลาย feature มากเช่น ส่วนของการสร้าง course เรียกกีฬา, จ่ายเงิน, จองสนามกีฬา, จองม้า และจองอื่นๆ, สร้าง course โดย ครู เป็นต้น
จากนั้นก็มี project ใหม่จากลูกค้าเข้ามา ซึ่งใช้ Django และ angular ในการเขียนโดยผมก็ได้ทำเกี่ยวกับการเอาข้อมูลอย่างนึงมาแปลงเป็นคำสั่งนึงแล้วส่งเข้า server ของลูกค้าผ่าน FTP และมีการทำหน้าเว็ปในการจัดการข้อมูลนั้น เป็นต้น</p>
  </div>
</template>

<script>
export default {
  name: "AboutMe",
  setup() {

  }
}
</script>

<style lang="scss">
.aboutme {
  padding: 0rem 3rem;
  animation: moveInBottom .5s ease-out .1s;
  animation-fill-mode: backwards;
  .room-mick-image {
    width: 70%;
  }
}
</style>